import {post, postForm} from '@/utils/request'

const fileApi = {
  ossServerApi: '/api/file/oss/serverinfo',
  ossUploadApi: '/api/file/upload',
  ossStsApi: '/api/file/aliyun/sts_token',
  ossSignedUrlApi: '/api/file/aliyun/signed_url'
}

export function getAvatarChannelInfo() {
  return post(fileApi.ossServerApi + '/image')
}

export function getVideoCoverChannelInfo() {
  return post(fileApi.ossServerApi + '/image')
}

export function getPhotoChannelInfo() {
  return post(fileApi.ossServerApi + '/photo')
}

export function getVideoChannelInfo() {
  return post(fileApi.ossServerApi + '/video')
}

export function getFileChannelInfo() {
  return post(fileApi.ossServerApi + '/file')
}

export function getStsToken() {
  return post(fileApi.ossStsApi)
}

export function getSignedUrl(data) {
  return post(fileApi.ossSignedUrlApi, data)
}

export function uploadFile(payload) {
  return postForm(fileApi.ossUploadApi, payload)
}
